import React from 'react';
import {
  PrismicRichText,
  PrismicText,
  usePrismicDocumentByUID,
} from '@prismicio/react';
import { Footer } from 'Components';
import './LegalPage.scss';

function LegalPage({ uid }) {
  const [document, { state }] = usePrismicDocumentByUID('static-page', uid);

  const loading = state !== 'loaded';
  const legalText = !loading ? document.data : [];
  return (
    <>
      <div id="legalPage">
        <div className="header">
          <PrismicText className="headerTitle" field={legalText.title} />
        </div>
        <div className="content">
          <PrismicRichText field={legalText.text} />
        </div>
      </div>
      <Footer />
    </>
  );
}
export default LegalPage;
