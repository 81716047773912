import React from 'react';
import classnames from 'classnames';
import packageInfo from '../../../package.json';
import { capitalize, upvoty, trackHandler } from 'utils';
import { AppStoreButtons } from 'UI';
import { useProfile, useFlags } from 'hooks';

import './Footer.scss';

export default function Footer(props) {
  const { className } = props;
  const { profile } = useProfile();
  const { flag: noPromoFlag } = useFlags('NO_PROMO');
  if (noPromoFlag) return null;
  return (
    <footer id="Footer" className={classnames(className)}>
      <p>
        Des milliers de passionné.e.s font partie de notre communauté, et toi ?{' '}
        <a
          className=""
          target="_blank"
          rel="noreferrer noopener"
          href="https://discord.gg/T3FAWnw"
          onClick={e => trackHandler(e, 'Discord Clicked')}
        >
          rejoins-nous !
        </a>
      </p>
      <ul className="center social-links">
        <li>
          <a
            className="link-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/T3FAWnw"
            onClick={e => trackHandler(e, 'Discord Clicked')}
          >
            <img
              src="https://cdn.mangas.io/images/home/discord.svg"
              width="36"
              height="36"
              alt="discord"
            />
          </a>
        </li>
        <li>
          <a
            className="link-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/mangas.io"
            onClick={e => trackHandler(e, 'Instagram Clicked')}
          >
            <img
              src="https://cdn.mangas.io/images/home/instagram.svg"
              width="36"
              height="36"
              alt="instagram"
            />
          </a>
        </li>
        <li>
          <a
            className="link-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/io_mangas"
            onClick={e => trackHandler(e, 'Twitter Clicked')}
          >
            <img
              src="https://cdn.mangas.io/images/home/twitter.svg"
              width="36"
              height="36"
              alt="twitter"
            />
          </a>
        </li>
        <li>
          <a
            className="link-item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.tiktok.com/@mangas.io"
            onClick={e => trackHandler(e, 'TikTok Clicked')}
          >
            <img
              src="https://cdn.mangas.io/images/home/tiktok.png"
              width="36"
              height="36"
              alt="tiktok"
            />
          </a>
        </li>
      </ul>
      <nav className="footer-menu">
        <ul>
          <li>
            <p className="title --text-secondary">mangas.io</p>
            <ul>
              <li>
                <a className="--text" href="/notre-service">
                  Notre service
                </a>
              </li>
              <li>
                <a className="--text" href="/team">
                  L'équipe
                </a>
              </li>
              <li>
                <a
                  className="--text"
                  href="/mentions-legales"
                  onClick={e => trackHandler(e, 'Legal Notice Clicked')}
                >
                  Mentions Légales
                </a>
              </li>
              <li>
                <a
                  className="--text"
                  href="/protection-des-donnees"
                  onClick={e => trackHandler(e, 'Privacy Policy Clicked')}
                >
                  Politique de protection des données
                </a>
              </li>
              <li>
                <a
                  className="--text"
                  href="/cgu"
                  onClick={e => trackHandler(e, 'Terms of Service Clicked')}
                >
                  Conditions générales d'utilisation
                </a>
              </li>
              <li>
                <a
                  className="--text"
                  href="/politique-de-cookies"
                  onClick={e => trackHandler(e, 'Cookie Policy Clicked')}
                >
                  Politique de cookies
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p className="title --text-secondary">Catalogue</p>
            <ul>
              <li>
                <a href="/manga-en-ligne" className="--text">
                  Tous nos mangas
                </a>
              </li>
              <li>
                <a href="/manga-en-ligne/seinen" className="--text">
                  Seinen
                </a>
              </li>
              <li>
                <a href="/manga-en-ligne/shonen" className="--text">
                  Shōnen
                </a>
              </li>
              <li>
                <a href="/manga-en-ligne/shojo" className="--text">
                  Shōjo
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p className="title --text-secondary">Liens utiles</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="https://www.notion.so/Kit-presse-Mangas-io-0e77f8f3ae19446f84f2b783733aaf3b"
                  onClick={e => trackHandler(e, 'Press Clicked')}
                >
                  Kit presse
                </a>
              </li>
              <li>
                <a
                  className="--text"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mangasio.upvoty.com/b/demande-de-fonctionnalite/"
                  onClick={e => {
                    upvoty.upvotySSO(profile);
                    trackHandler(e, 'Feature Request Clicked');
                  }}
                >
                  Proposer une fonctionnalité
                </a>
              </li>
              <li>
                <a
                  className="--text"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mangasio.upvoty.com/b/corrections-et-bugs/"
                  onClick={e => {
                    upvoty.upvotySSO(profile);
                    trackHandler(e, 'Bug Report Clicked');
                  }}
                >
                  Signaler un bug ou une correction
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="https://cdn.mangas.io/livre-blanc.pdf"
                  onClick={e => trackHandler(e, 'White Paper Clicked')}
                >
                  Livre blanc
                </a>
              </li>
            </ul>
          </li>
          <li>
            <p className="title --text-secondary">Nous contacter</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="/faq"
                  onClick={e =>
                    trackHandler(e, 'FAQ Clicked', {
                      type: 'support',
                      source: 'footer',
                    })
                  }
                >
                  FAQ / SAV
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="mailto:jobs@mangas.io?subject=💌 Recrutements / Candidatures"
                  onClick={e =>
                    trackHandler(e, 'Contact Clicked', { type: 'careers' })
                  }
                >
                  Recrutements / Candidatures
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="mailto:presse@mangas.io?subject=💌 Medias / Influenceurs"
                  onClick={e =>
                    trackHandler(e, 'Contact Clicked', { type: 'press' })
                  }
                >
                  Medias / Influenceurs
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="--text"
                  href="mailto:pro@mangas.io?subject=💌 Partenariats / Professionnels"
                  onClick={e =>
                    trackHandler(e, 'Contact Clicked', { type: 'partners' })
                  }
                >
                  Partenariats / Professionnels
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="download-content">
          <div className="download-ctas">
            <AppStoreButtons />
          </div>
        </div>
      </nav>

      <div className="footer-version">
        <p className="--text-secondary">
          Version {packageInfo.version} - {capitalize(packageInfo.versionName)}
          {/* <img
            className="version-icon"
            src={`https://cdn.mangas.io/landing/icon-version-${versionName}.svg`}
            alt={versionName}
          /> */}
        </p>
      </div>
    </footer>
  );
}
