import qs from 'qs';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { getGA4ClientId } from './ga4';

export const getAnonymousId = () => {
  // Retrieve Segment anonymous ID from cookies or generate a new one
  let anonymousId = Cookies.get('ajs_anonymous_id') || null;
  if (!anonymousId) {
    anonymousId = uuidv4();
    Cookies.set('ajs_anonymous_id', anonymousId, {
      expires: 365,
      sameSite: 'lax',
    });
  }

  return anonymousId;
};

export const trackHandler = async (e, event, properties = {}) => {
  // e.preventDefault();
  // const url = e.target.closest('a').href;

  // const clientId = await getGA4ClientId();

  // window.location.href = trackLink(url, event, {
  //   ...properties,
  //   tracking: {
  //     ga4ClientId: clientId,
  //   },
  // });
  return null;
};

export const trackLink = (url, event, properties = {}) => {
  if (typeof window !== 'undefined' && event) {
    const queryParams = qs.stringify(
      {
        anonymousId: getAnonymousId(),
        properties,
        context: {
          event,
          locale: navigator.language,
          page: {
            path: window.location.pathname,
            referrer: document.referrer,
            search: window.location.search,
            title: document.title,
            url: window.location.href,
          },
        },
        url,
      },
      {
        addQueryPrefix: true,
        allowDots: true,
      }
    );
    return `${process.env.REACT_APP_AWS_API_URL}/track${queryParams}`;
  }
  return url;
};

export const track = async (event, properties = {}, context = {}) => {
  if (!window.analytics) return;
  const clientId = await getGA4ClientId();
  window.analytics.track(event, properties, {
    ...context,
    ga4ClientId: clientId,
  });
};

export const trackPage = async (event, properties = {}, context = {}) => {
  if (!window.analytics) return;
  const clientId = await getGA4ClientId();
  window.analytics.page(properties, {
    ...context,
    ga4ClientId: clientId,
  });
};

export const identify = async (userId, properties = {}, context = {}) => {
  if (!window.analytics) return;
  const clientId = await getGA4ClientId();
  window.analytics.identify(userId, properties, {
    ...context,
    ga4ClientId: clientId,
  });
};
