import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './carrousel.scss';

import useComputedSizes from '../../hooks/useComputedSizes';
import useEventListener from '../../hooks/useEventListener';

import Icon from '@mdi/react';
import { mdiChevronLeft } from '@mdi/js';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const SCROLL_TOLERANCE = 20; // px

const Carrousel = ({
  scrollable = true,
  withControls = false,
  withShadow = false,
  className,
  children,
  ...props
}) => {
  const [ref, { width }] = useComputedSizes();
  const [displayLeftControl, setDisplayLeftControl] = useState(false);
  const [displayRightControl, setDisplayRightControl] = useState(false);

  function scrollRight() {
    if (!ref.current) return null;
    ref.current.scrollTo({
      left: getLastVisibleElementOffset(),
      behavior: 'smooth',
    });
  }
  function scrollLeft() {
    if (!ref.current) return null;
    ref.current.scrollTo({
      left: getFirstVisibleElementOffset() - width,
      behavior: 'smooth',
    });
  }
  function getLastVisibleElementOffset() {
    const endBoundary = width + ref.current.scrollLeft;
    const lastVisibleElement = Array.from(ref.current.children)
      .filter(child => child.offsetLeft < endBoundary)
      .pop();
    return lastVisibleElement.offsetLeft;
  }
  function getFirstVisibleElementOffset() {
    const startBoundary = ref.current.scrollLeft;
    const firstVisibleElement = Array.from(ref.current.children)
      .filter(child => child.offsetLeft + child.offsetWidth > startBoundary)
      .shift();
    return firstVisibleElement.offsetLeft + firstVisibleElement.offsetWidth;
  }

  function handleScroll(e) {
    if (e.target.scrollLeft === 0) setDisplayLeftControl(false);
    else if (!displayLeftControl) setDisplayLeftControl(true);
    if (e.target.scrollLeft + width + SCROLL_TOLERANCE >= e.target.scrollWidth)
      setDisplayRightControl(false);
    else if (!displayRightControl) setDisplayRightControl(true);
  }

  function initialDisplayOfRightControl() {
    if (
      ref.current &&
      width > 0 &&
      ref.current.scrollWidth > width + SCROLL_TOLERANCE
    ) {
      setDisplayRightControl(true);
    } else setDisplayRightControl(false);
  }

  useEventListener('scroll', handleScroll, ref);
  useEventListener('resize', initialDisplayOfRightControl);
  useEffect(initialDisplayOfRightControl, [ref.current, children]);

  return (
    <div
      className={classnames('mio-carrousel', { scrollable }, className)}
      {...props}
    >
      {withShadow && (
        <>
          <div
            className={classnames('carrousel-shadow right', {
              hidden: !displayRightControl,
            })}
          ></div>
          <div
            className={classnames('carrousel-shadow left', {
              hidden: !displayLeftControl,
            })}
          ></div>
        </>
      )}

      <div className="elements" ref={ref}>
        {children}
      </div>

      {withControls && (
        <>
          <div
            className={classnames('controls left', {
              hidden: !displayLeftControl,
            })}
            onClick={scrollLeft}
          >
            <Icon path={mdiChevronLeft} />
          </div>

          <div
            className={classnames('controls right', {
              hidden: !displayRightControl,
            })}
            onClick={scrollRight}
          >
            <Icon path={mdiChevronLeft} />
          </div>
        </>
      )}
    </div>
  );
};

export default Carrousel;
