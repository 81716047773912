import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

import './button.scss';

const Button = ({
  variant = 'secondary',
  disabled = false,
  size = 'medium',
  label,
  fullWidth = false,
  icon = null,
  loading = false,
  className,
  ...props
}) => {
  const labels = Array.isArray(label) ? label : [label];
  return (
    <button
      type="button"
      className={classnames(
        'mio-button',
        { spaceBetween: labels.length > 1 },
        className,
        {
          primary: variant === 'primary',
          dark: variant === 'dark',
          light: variant === 'light',
          secondary: variant === 'secondary',
          text: variant === 'text',
          gold: variant === 'gold',
          disabled,
          fullWidth,
        },
        { [size]: true },
        { withIcon: !!icon || loading }
      )}
      {...props}
    >
      {loading ? <Icon path={mdiLoading} className="spin" /> : icon}
      {labels.map((label, index) => (
        <span className="label" key={index}>
          {label}
        </span>
      ))}
    </button>
  );
};

export default Button;
