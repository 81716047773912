import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import { Toaster } from 'react-hot-toast';

import * as prismic from '@prismicio/client';
import { PrismicProvider } from '@prismicio/react';

import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

import { RecoilRoot } from 'recoil';

import ApiProvider from 'utils/ApiProvider';
import { RouterWithScrollTop as Router } from 'utils';
import {
  Navbar,
  ResponsiveDebugger,
  NotFound,
  Noop,
  EANRedirector,
  StateEffects,
  Modal,
} from 'Components';
import {
  AuthorPage,
  BlogPage,
  PublisherPage,
  HomePage,
  LandingPage,
  MangaPage,
  ReaderPage,
  FavoritePage,
  CollectionPage,
  LoginPage,
  SignupPage,
  ResetPasswordPage,
  PublicLibrary,
  Pricing,
  VoucherPage,
  PassCulturePage,
  PassCulturePageLegacy,
  ReferralPage,
  SubCategories,
  SubscriptionPage,
  VolumePage,
  PassCultureLanding,
  ActivationPassword,
  RedeemPageLibrary,
  Team,
  RedeemPageGroupon,
  RedeemPageFuzeForge,
  RedeemPageMcdo,
  FavoriteCategories,
  Faq,
  JoinFamily,
  LegalPage,
} from 'Pages';
import {
  useAuth,
  useProfile,
  AppStateProvider,
  useAppState,
  skipLandingSelector,
} from 'hooks';
import {
  ErrorBoundary,
  SwitchRoute,
  PublicRoute,
  PrivateRoute,
  track,
} from 'utils';

import 'normalize.css';
import 'design-system/global.css';
import './App.scss';
import './theme.scss';

function App() {
  const { isAuthenticated, loading: authLoading } = useAuth();
  const { loading: profileLoading } = useProfile();
  const [skipLanding] = useAppState(skipLandingSelector);

  useEffect(() => {
    track('Web App Opened');
  }, []);

  if (authLoading || profileLoading) return null;

  return (
    <div className="App dark-mode">
      <Switch>
        <Noop
          path={[
            '/parrainage',
            '/carte-cadeau/activation',
            '/login',
            '/activation',
            '/reset',
            '/signup',
            '/bibliotheque',
            '/fuzeforge',
            '/groupon',
            '/mcdo',
            '/abonnement',
            '/pass-culture',
            '/activation-pass-culture',
            '/family',
            '/joinfamily',
          ]}
        />
        <Navbar path="/" />
      </Switch>
      <ResponsiveDebugger />
      <Switch>
        <SwitchRoute exact path="/">
          <HomePage displayIf={isAuthenticated || skipLanding} />
          <LandingPage displayIf={!isAuthenticated && !skipLanding} />
        </SwitchRoute>
        <PublicRoute path="/lire/:slug" component={<MangaPage />} exact />
        <PublicRoute path="/volume/:ean" component={<VolumePage />} exact />
        <PublicRoute path="/author/:slug" component={<AuthorPage />} exact />
        <PublicRoute
          path="/publisher/:slug"
          component={<PublisherPage />}
          exact
        />

        <PublicRoute path="/blog" component={<BlogPage />} />
        <PublicRoute path="/carte-cadeau" component={<VoucherPage />} />
        <PublicRoute path="/faq" component={<Faq />} />

        <PublicRoute
          path={['/bibliotheque/:code', '/bibliotheque']}
          component={<RedeemPageLibrary />}
        />
        <PublicRoute
          path={['/groupon/:code', '/groupon']}
          component={<RedeemPageGroupon />}
        />
        <PublicRoute
          path={['/fuzeforge/:code', '/fuzeforge']}
          component={<RedeemPageFuzeForge />}
        />
        <PublicRoute
          path={['/mcdo/:code', '/mcdo']}
          component={<RedeemPageMcdo />}
        />
        <PublicRoute
          path={[
            '/joinfamily/:code',
            '/joinfamily',
            '/family/:code',
            '/family',
          ]}
          component={<JoinFamily />}
        />
        <PublicRoute path="/ean/:ean" component={<EANRedirector />} />
        <PublicRoute
          exact
          path={['/lire/:slug/:chapterNb', '/lire/:slug/:chapterNb/:pageNb']}
          component={<ReaderPage />}
        />
        <SwitchRoute path="/login">
          <LoginPage displayIf={!isAuthenticated} />
          <Redirect to="/" displayIf={isAuthenticated} />
        </SwitchRoute>
        <PublicRoute path="/reset" component={<ResetPasswordPage />} />
        <PublicRoute path="/activation" component={<ActivationPassword />} />
        <PublicRoute path="/categorie" component={<SubCategories />} />
        <PublicRoute path="/manga-en-ligne" component={<PublicLibrary />} />
        <PublicRoute path="/signup" component={<SignupPage />} />
        <PublicRoute
          path="/favoriteCategories"
          component={<FavoriteCategories />}
        />
        <PublicRoute path="/tarifs" component={<Pricing />} />
        <PublicRoute path="/team" component={<Team />} />
        <PublicRoute path="/cgu" component={<LegalPage uid="cgu" />} />
        <PublicRoute
          path="/mentions-legales"
          component={<LegalPage uid="mentions-legales" />}
        />
        <PublicRoute
          path="/protection-des-donnees"
          component={<LegalPage uid="politique-de-protection-des-donnees" />}
        />
        <PublicRoute
          path="/politique-de-cookies"
          component={<LegalPage uid="politique-de-cookies" />}
        />

        <PublicRoute
          path={['/parrainage/:code', '/parrainage']}
          component={<ReferralPage />}
        />
        <PublicRoute
          path={[
            '/pass-culture/activation/:code',
            '/pass-culture/activation',
            '/pass-culture/:code',
          ]}
          component={<PassCulturePageLegacy />}
        />
        <PublicRoute path="/pass-culture" component={<PassCultureLanding />} />
        <PublicRoute
          path={['/activation-pass-culture/:code', '/activation-pass-culture']}
          component={<PassCulturePage />}
        />

        <PublicRoute
          path="/notre-service"
          component={<LandingPage noindex />}
        />
        <PublicRoute path="/collection/:id" component={<CollectionPage />} />

        <PrivateRoute path="/abonnement" component={<SubscriptionPage />} />
        <PrivateRoute path="/library" component={<FavoritePage />} />

        <Route
          path="/plan"
          component={({ location }) => (
            <Redirect to={{ ...location, pathname: '/abonnement' }} />
          )}
        />
        <Route
          path="/payment/success"
          component={({ location }) => (
            <Redirect to={{ ...location, pathname: '/abonnement/success' }} />
          )}
        />
        <Route
          path="/payment/cancelled"
          component={({ location }) => (
            <Redirect to={{ ...location, pathname: '/abonnement' }} />
          )}
        />
        <PublicRoute default component={<NotFound />} />
      </Switch>
    </div>
  );
}

function AppContainer({ history }) {
  const endpoint = prismic.getEndpoint(process.env.REACT_APP_PRISMIC_REPO_NAME);
  const client = prismic.createClient(endpoint);
  return (
    <Router history={history}>
      <ErrorBoundary>
        <ApiProvider>
          <FlagsmithProvider
            options={{
              environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
            }}
            flagsmith={flagsmith}
          >
            <PrismicProvider client={client}>
              <RecoilRoot>
                <AppStateProvider>
                  <StateEffects />
                  <App />
                  <Modal />
                  <Toaster position="bottom-left" />
                </AppStateProvider>
              </RecoilRoot>
            </PrismicProvider>
          </FlagsmithProvider>
        </ApiProvider>
      </ErrorBoundary>
    </Router>
  );
}

export default AppContainer;
