import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Switch, MemoryRouter } from 'react-router-dom';

import { Input, Button, Text } from 'design-system';

import {
  CaptiveNavbar,
  LandingFaq,
  LandingFeatures,
  LandingHero,
  PublishersBloc,
  LandingPopularManga,
  LandingUsersFeedback,
  MinimalFooter,
  Success,
  DisclaimerInvited,
} from 'Components';

import { PublicRoute } from 'utils';
import {
  useAuth,
  useAppState,
  useProfile,
  BrowserHistoryProvider,
  useBrowserHistory,
} from 'hooks';

import { OnboardingBackground } from 'UI';

import '../Landing/LandingPage.scss';
import './PassCulturePage.scss';

const errors = {
  'Invalid code': 'Code invalide',
  'Code limit reached': 'Code déjà utilisé',
  INVALID_STATUS_SEQUENCE: "Impossible d'activer ce code sur votre compte",
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (errors[error]) return errors[error];
  return errors.default;
}

function PassCulturePage({ code = '' }) {
  const { isAuthenticated, validateInvite, addInvite } = useAuth();
  const [currentCode, setCode] = useState(code);
  const [disabled, setDisabled] = useState(currentCode.length === 0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [, actions] = useAppState();
  const history = useHistory();
  const browserHistory = useBrowserHistory();
  const [isPayingUser, setIsPayingUser] = useState(false);
  const { profile, loading: profileLoading, refreshProfile } = useProfile();

  useEffect(() => {
    if (profile && profile.info) {
      if (!['visitor', 'invited', 'voucher'].includes(profile.info.status)) {
        setIsPayingUser(true);
        setDisabled(true);
      }
    }
  }, [profileLoading, profile]);

  useEffect(() => {
    setDisabled(!isPayingUser && currentCode.length === 0);
  }, [currentCode, isPayingUser]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!isAuthenticated) {
      const { error, valid } = await validateInvite(currentCode);
      setLoading(false);
      if (!valid) setError(getErrorMessage(error));
      else {
        actions.setVoucherAction(currentCode);
        browserHistory.push('/signup');
      }
    } else {
      const { error, valid } = await addInvite(currentCode);
      setLoading(false);
      if (!valid) setError(getErrorMessage(error));
      else {
        actions.setVoucherAction(null);
        await refreshProfile();
        history.push('/success');
      }
    }
  };

  if (profileLoading) return null;

  return (
    <div id="Landing" className="PassCulturePage">
      <CaptiveNavbar
        cta={
          <Button
            variant="primary"
            label="Activer l’offre"
            loading={loading}
            disabled={disabled}
            onClick={!disabled ? handleSubmit : undefined}
          />
        }
      />
      <header>
        <LandingHero
          tags={['Sans engagement', 'Pas de publicité', `100k+ passionné.e.s`]}
          title="Pass Culture t'offre ton abonnement&nbsp;!"
          subtitle={
            isPayingUser ? (
              <>
                Tu as déjà un abonnement Mangas.io en cours. Il n'est
                actuellement pas possible de prolonger un abonnement payant avec
                une offre pass-culture.
              </>
            ) : (
              `Active ton offre et accède à des dizaines de milliers de chapitres à lire en illimité sur Mangas.io !`
            )
          }
        >
          <div className="code-validation-input">
            <Input
              value={currentCode}
              onChange={e => setCode(e.target.value)}
              placeholder="Ton code Pass Culture"
              highlight={error ? 'error' : null}
              helper={error}
            />
            <Button
              label="Activer"
              variant="primary"
              size="large"
              loading={loading}
              disabled={disabled}
              onClick={!disabled ? handleSubmit : undefined}
            />
          </div>
          <DisclaimerInvited profile={profile} />
        </LandingHero>
      </header>

      <section id="advantage" className="section">
        <PublishersBloc />
      </section>

      <section id="faq" className="section">
        {/* TODO : utiliser la bonne FAQ */}
        <LandingFaq title="Questions fréquentes" page_name="faq_landing" />
      </section>

      <section id="popularManga" className="section">
        <LandingPopularManga hideCTA />
      </section>

      <section id="features" className="section">
        <LandingFeatures />
      </section>

      <section id="feedback" className="section">
        <LandingUsersFeedback />
      </section>

      <MinimalFooter />
    </div>
  );
}

function PassCultureSuccess({ onClick }) {
  const history = useBrowserHistory();
  // TODO : event tracking ?
  return (
    <OnboardingBackground id="mio-loginPage">
      <Success>
        <Text bold>Félicitations !</Text>
        <Text>
          Ton offre pass culture a bien été activée. Bonne lecture sur Mangas.io
          !
        </Text>
        <Button
          fullWidth
          className="divider"
          variant="primary"
          label="Continuer"
          onClick={() => history.push('/')}
        />
      </Success>
    </OnboardingBackground>
  );
}

export default function PassCulturePageRouter() {
  const { code } = useParams();
  return (
    <BrowserHistoryProvider>
      <MemoryRouter>
        <Switch>
          <PublicRoute path="/success" component={<PassCultureSuccess />} />
          <PublicRoute path="/" component={<PassCulturePage code={code} />} />
        </Switch>
      </MemoryRouter>
    </BrowserHistoryProvider>
  );
}
