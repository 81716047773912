import React from 'react';
import classnames from 'classnames';
import './text.scss';

const Text = ({
  bold = false,
  type = 'text',
  size = 'medium',
  mobile = false,
  as: Tag = 'p',
  className,
  ...props
}) => {
  return (
    <Tag
      className={classnames(
        'mio-text',
        { bold, mobile },
        { [size]: true, [type]: true },
        className
      )}
      {...props}
    ></Tag>
  );
};

export default Text;
