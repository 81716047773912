import React from 'react';
import classnames from 'classnames';
import './pill.scss';

const Pill = ({
  size = 'medium',
  backgroundColor,
  style,
  className,
  ...props
}) => {
  return (
    <span
      className={classnames('mio-pill', { [size]: true }, className)}
      {...props}
      style={{ ...style, backgroundColor: backgroundColor }}
    >
      {props.children}
    </span>
  );
};

export default Pill;
